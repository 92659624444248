import { MenuPlugin, PluginConfiguration } from '@voicefoundry-cloud/vf-omp-shared';
import { Amplify, Auth } from 'aws-amplify';
import { Language, languages } from 'shared/constants/Languages';
import { getLogger, Logger } from './LoggingService';

interface ICognitoConfig {
  userPoolId: string;
  clientId: string;
  identityPoolId: string;
  domain: string;
  hostedAuthenticationUrl: string;
  logoutUrl: string;
}

interface RTColumn {
  field: string;
  headerName: string;
  hide?: boolean;
  width?: number;
}

type RTMetricsTableConfiguration = RTColumn[];

interface RTMetricsTablesConfigurations {
  realTimeQueueMetrics: RTMetricsTableConfiguration;
  realTimeRoutingProfileMetrics: RTMetricsTableConfiguration;
  realTimeAgentMetrics: RTMetricsTableConfiguration;
}
export default class ConfigurationService {
  private logger: Logger;
  loaded = false;
  // These properties are assigned from config.json
  environment: string = '';
  region: string = '';
  apiUrl: string = '';
  cognito: ICognitoConfig = {
    userPoolId: '',
    clientId: '',
    identityPoolId: '',
    domain: '',
    hostedAuthenticationUrl: '',
    logoutUrl: '',
  };
  private supportedLanguagesCsv: string = '';
  version: string = '';
  buildTime: string = '';

  metricsTableConfigurations: RTMetricsTablesConfigurations = {
    realTimeQueueMetrics: null,
    realTimeRoutingProfileMetrics: null,
    realTimeAgentMetrics: null,
  };
  maxSelectableCtrRows: number = 100;
  supportedLanguages: Language[] = languages;
  disclaimerMessage: string = '';
  addYearMonthDayPartitionConditions: boolean = false;
  plugins: MenuPlugin[] = [];

  constructor() {
    this.logger = getLogger('ConfigurationService');
  }

  private async fetchCustomTableConfigs() {
    try {
      return await fetch('/customMetricsTableConfigs.json').then(res => res.json());
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  public async load() {
    const response = await fetch('/config.json');
    //const response = await fetch('/api/configuration');
    const cfg = await response.json();
    const customTableConfigs = await this.fetchCustomTableConfigs();
    Object.assign(this, cfg, {
      metricsTableConfigurations: customTableConfigs ? customTableConfigs : this.metricsTableConfigurations,
    });
    this.maxSelectableCtrRows = parseInt(cfg.maxSelectableCtrRows) || 100;

    if (this.supportedLanguagesCsv) {
      // Load languages from config.json
      this.supportedLanguages = this.supportedLanguagesCsv.split(',').map(x => ({ name: x, code: x }));
    }
    this.disclaimerMessage = cfg?.disclaimerMessage || '';
    this.addYearMonthDayPartitionConditions = cfg?.addYearMonthDayPartitionConditions || false;
    const pluginsResponse = await fetch('/api/config/plugins');
    const pluginConfiguration: PluginConfiguration = await pluginsResponse.json();
    this.plugins = pluginConfiguration.plugins;

    this.logger.debug('Config Loaded');
    this.configureAmplify();
    this.loaded = true;
  }

  get loginUrl(): string {
    return window.location.origin;
  }

  isLocal(): boolean {
    return this.environment === 'local';
  }
  isDev(): boolean {
    return this.environment === 'dev';
  }

  private configureAmplify(): void {
    Amplify.Logger.LOG_LEVEL = this.isLocal() || this.isDev() ? 'DEBUG' : 'INFO';
    const options = {
      Analytics: {
        disabled: true,
      },
      Auth: {
        region: this.region,
        userPoolId: this.cognito.userPoolId,
        userPoolWebClientId: this.cognito.clientId,
        //identityPoolId: this.cognito.identityPoolId,
        oauth: {
          domain: this.cognito.domain,
          scope: ['email', 'openid', 'profile'],
          redirectSignIn: this.loginUrl,
          redirectSignOut: this.cognito.logoutUrl ? this.cognito.logoutUrl : this.loginUrl + '/logout/',
          responseType: 'code',
          mandatorySignIn: true,
        },
      },
    };
    this.logger.debug(JSON.stringify(options));
    Auth.configure(options);
  }
}
